import firebase from "firebase";

export const createGraph = (user: firebase.User) => () => {
  firebase.firestore().collection("graphs").add({
    author: user.uid,
  });
};

export const deleteGraph = (graphId: string) => () => {
  firebase.firestore().collection("graphs").doc(graphId).delete();
};
