import Box from "@material-ui/core/Box/Box";
import Container from "@material-ui/core/Container/Container";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import firebase from "firebase";
import React from "react";
import { createGraph, deleteGraph } from "../utils/graphs/GraphManagment";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Graph from "../types/graphs/Graph";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import DeleteIcon from '@material-ui/icons/Delete';
import {Link} from "react-router-dom";

interface HomeProps {
  user: firebase.User;
}

function Home(props: HomeProps) {
  const { user } = props;

  const [value, loading, error] = useCollectionData<Graph>(
    firebase.firestore().collection("graphs").where("author", "==", user.uid),
    { idField: "id" }
  );

  console.log(value, loading, error);

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <List>
          <ListItem button onClick={createGraph(user)}>
            <ListItemText>Create new graph</ListItemText>
          </ListItem>
          {value?.map((it) => (
            <ListItem key={it.id} button component={Link} to={`/graph/${it.id}`}>
              <ListItemText>{it.name!=null?it.name:it.id}</ListItemText>
              <ListItemSecondaryAction onClick={deleteGraph(it.id)}>
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
}

export default Home;
