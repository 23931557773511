import React from "react";

import "./App.scss";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import { ThemeProvider } from "@material-ui/core/styles";

import BurgerMenu from "./components/BurgerMenu";
import Home from "./components/Home";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./utils/firebase/Firebase";
import LogInReminder from "./components/LogInReminder";
import GraphComponent from "./components/GraphComponent";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  const [user] = useAuthState(auth);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <BurgerMenu user={user} />
        {user == null ? (
          <LogInReminder />
        ) : (
          <Switch>
            <Route path="/graph/:graphId">
              <GraphComponent />
            </Route>
            <Route path="/">
              <Home user={user} />
            </Route>
          </Switch>
        )}
      </Router>
    </ThemeProvider>
  );
}

export default App;
