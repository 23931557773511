import React, { useState } from "react";

import firebase from "firebase";
import Drawer from "@material-ui/core/Drawer/Drawer";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Avatar from "@material-ui/core/Avatar/Avatar";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import { Link } from "react-router-dom";

interface BurgerMenuProps {
  user: firebase.User;
}

const BurgerMenu = (props: BurgerMenuProps) => {
  const { user } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className={`burger-menu__button ${open ? "burger-menu--open" : ""}`}
        onClick={() => setOpen(!open)}
      >
        <div />
        <div />
        <div />
      </button>

      <Drawer
        variant="temporary"
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          className: "burger-menu__container",
        }}
      >
        <List>
          {user == null ? (
            <BurgerMenuSignIn />
          ) : (
            <BurgerMenuLoggedIn user={user} />
          )}
        </List>
      </Drawer>
    </>
  );
};

interface BurgerMenuLoggedInProps {
  user: firebase.User;
}

const BurgerMenuLoggedIn = (props: BurgerMenuLoggedInProps) => {
  const { user } = props;

  return (
    <>
      <UserBadge user={user} />
      <ListItem button component={Link} to="/">
      <ListItemText>Main menu</ListItemText>
        </ListItem>
      <ListItem
        button
        onClick={() => {
          firebase.auth().signOut();
        }}
      >
        <ListItemText>Sign Out</ListItemText>
      </ListItem>
    </>
  );
};

const BurgerMenuSignIn = () => {
  return (
    <>
      <ListItem
        button
        onClick={() => {
          const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
          firebase
            .auth()
            .signInWithPopup(googleAuthProvider)
            .then((auth) => {
              firebase.firestore().collection("users").doc(auth.user?.uid).set({
                displayName: auth.user?.displayName,
                email: auth.user?.email,
                photoURL: auth.user?.photoURL,
              });
            });
        }}
      >
        <ListItemText>Sign In with Google</ListItemText>
      </ListItem>
    </>
  );
};

interface UserBadgeProps {
  user: firebase.User;
}

const UserBadge = (props: UserBadgeProps) => {
  const { user } = props;
  return (
    <div className="user-badge">
      <Avatar
        className="large"
        src={user.photoURL || undefined}
        alt={user.displayName || undefined}
      ></Avatar>
    </div>
  );
};

export default BurgerMenu;
