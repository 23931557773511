import Box from "@material-ui/core/Box/Box";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import React from "react";

function LogInReminder() {
  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4">Please log in</Typography>
      </Box>
    </Container>
  );
}

export default LogInReminder;
