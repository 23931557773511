import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography/Typography";
import firebase from "firebase";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";

import {
  ForceGraph2D,
  ForceGraph3D,
  ForceGraphAR,
  ForceGraphVR,
} from "react-force-graph";
import { useParams } from "react-router-dom";
import ForceGraphCommonFunctions from "../types/force-graph/ForceGraphCommonFunctions";
import ForceGraphCommonProps from "../types/force-graph/ForceGraphCommonProps";
import { ForceGraphType } from "../types/force-graph/ForceGraphType";
import GraphData from "../types/graphs/GraphData";
import GraphEdge from "../types/graphs/GraphEdge";
import GraphNode from "../types/graphs/GraphNode";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

interface GraphComponentProps {}

interface GraphComponentRouteProps {
  graphId: string;
}

function GraphComponent(props: GraphComponentProps) {
  const { graphId } = useParams<GraphComponentRouteProps>();

  const [data, setData] = useState<GraphData>({
    nodes: [{ id: 0 }],
    links: [],
  });

  const [createEdgeState, setCreateEdgeState] = useState<{
    firstNode?: GraphNode
  }>({})

  const ref = useRef<ForceGraphCommonFunctions>();
  const [menuState, setMenuState] = React.useState<{
    mouseX?: number;
    mouseY?: number;
    elementType?: "background" | "node" | "edge";
    element?: GraphNode | GraphEdge;
  }>({});

  useEffect(() => {
    let forceGraph = ref.current;
    if (forceGraph != null) {
      let charge = forceGraph.d3Force("charge");
      charge?.distanceMax(50);

      let link = forceGraph.d3Force("link");
      link?.distance((edge) => {
        return edge.distance ? edge.distance : 30;
      });
    }
  });

  const [graphType, setGraphType] = useState<ForceGraphType>("3d");

  const [graphDoc, loading, error] = useDocumentData<GraphData>(
    firebase.firestore().collection("graphs").doc(graphId),
    { idField: "id" }
  );

  const [graphNodes, nodesLoading, nodesError] = useCollectionData<GraphNode>(
    firebase.firestore().collection("graphs").doc(graphId).collection("nodes"),
    { idField: "id" }
  );

  const [graphLinks, linksLoading, linksError] = useCollectionData<GraphEdge>(
    firebase.firestore().collection("graphs").doc(graphId).collection("edges"),
    { idField: "id" }
  );

  useEffect(() => {
    if (graphLinks != null && graphNodes != null) {
      setData(({ nodes, links }) => {
        return {
          nodes: graphNodes.map((node) => ({
            ...nodes.find((oldNode) => oldNode.id === node.id),
            ...node,
          })),
          links: graphLinks.map((link) => ({
            ...links.find((oldLink) => oldLink.id === link.id),
            ...link,
          })),
        };
      });
    }
  }, [graphNodes, graphLinks]);

  const finalLoading = loading && nodesLoading && linksLoading;
  const finalError = error || nodesError || linksError;

  if (finalError != null) {
    console.error(finalError);
    return (
      <Typography variant="h3" align="center">
        {finalError != null ? finalError : "Loading"}
      </Typography>
    );
  }

  if (
    graphDoc == null ||
    graphNodes == null ||
    graphLinks == null ||
    data == null ||
    finalLoading === true
  ) {
    return (
      <Typography variant="h3" align="center">
        Loading
      </Typography>
    );
  }

  const onNodeClick = (node: GraphNode, e: MouseEvent) => {
    if(createEdgeState.firstNode != null && createEdgeState.firstNode !== node){
      const newEdge: GraphEdge = {
        source: createEdgeState.firstNode.id,
        target: node.id
      }
      firebase
      .firestore()
      .collection("graphs")
      .doc(graphId)
      .collection("edges")
      .add(newEdge);
    }
  }

  const toggleMenuBackground = (e: MouseEvent) => {
    setMenuState({
      mouseX: e.pageX,
      mouseY: e.pageY,
      elementType: "background",
    });
  };

  const toggleMenuNode = (node: GraphNode, e: MouseEvent) => {
    setMenuState({
      mouseX: e.pageX,
      mouseY: e.pageY,
      elementType: "node",
      element: node,
    });
  };

  const toggleMenuEdge = (edge: GraphEdge, e: MouseEvent) => {
    setMenuState({
      mouseX: e.pageX,
      mouseY: e.pageY,
      elementType: "edge",
      element: edge,
    });
  };

  const closeMenu = (e: MouseEvent) => {
    setMenuState({});
  };

  const changeGraphType = () => {
    setGraphType((curr) => {
      return curr === "2d" ? "3d" : curr === "3d" ? "2d" : "2d";
    });
  };

  const addNode = () => {
    firebase
      .firestore()
      .collection("graphs")
      .doc(graphId)
      .collection("nodes")
      .add({});
  };

  const joinNode = (node?: GraphNode) => () => {
    if (node == null) {
      return;
    }
    setCreateEdgeState({firstNode: node})
  }

  const deleteNode = (node?: GraphNode) => () => {
    if (node?.id == null) {
      return;
    }

    data.links.filter(edge=>edge.source === node || edge.target === node).forEach(edge=>deleteEdge(edge)())

    firebase
      .firestore()
      .collection("graphs")
      .doc(graphId)
      .collection("nodes")
      .doc(node.id.toString())
      .delete();
  };

  const deleteEdge = (edge?: GraphEdge) => () => {
    if (edge?.id == null) {
      return;
    }

    firebase
      .firestore()
      .collection("graphs")
      .doc(graphId)
      .collection("edges")
      .doc(edge.id.toString())
      .delete();
  };

  const handleMenuClick = (f: () => void, close: boolean = true) => (
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    f();
    if (close) {
      setMenuState({});
    }
  };

  const commonGraphProps: ForceGraphCommonProps = {
    graphData: data,
    nodeColor: (node: GraphNode) => (node.color ? node.color : "white"),
    linkColor: (edge: GraphEdge) => (edge.color ? edge.color : "white"),
    linkWidth: (edge: GraphEdge) => (edge.width ? edge.width : 1),
    linkLabel: (edge: GraphEdge) => (edge.id ? edge.id.toString() : ""),
    nodeLabel: (node: GraphNode) => (node.id ? node.id.toString() : ""),
    onBackgroundRightClick: toggleMenuBackground,
    onNodeClick: onNodeClick,
    onNodeRightClick: toggleMenuNode,
    onLinkRightClick: toggleMenuEdge,
    //onBackgroundClick: closeMenu,
    ref: ref,
  };

  const contextMenu = () => {
    if (menuState.elementType === "background") {
      return [
        <MenuItem id="ch-view-type" onClick={handleMenuClick(changeGraphType, false)}>
          Change view type (curr: {graphType})
        </MenuItem>,
        <MenuItem id="add-node" onClick={handleMenuClick(addNode, false)}>Add node</MenuItem>,
      ];
    } else if (menuState.elementType === "node") {
      return [
        <MenuItem id="join-node" onClick={handleMenuClick(joinNode(menuState.element))}>
          Join to node
        </MenuItem>,
        <MenuItem id="delete-node" onClick={handleMenuClick(deleteNode(menuState.element))}>
          Delete node
        </MenuItem>,
      ];
    } else if (menuState.elementType === "edge") {
      return [
        <MenuItem id="delete-edge" onClick={handleMenuClick(deleteEdge(menuState.element))}>
          Delete edge
        </MenuItem>,
      ];
    }
  };

  console.log(data);

  return (
    <>
      <Typography variant="h3" align="center">
        {graphId}
      </Typography>
      <Box className="force-graph">
        {graphType === "3d" ? (
          <ForceGraph3D {...commonGraphProps} />
        ) : graphType === "2d" ? (
          <ForceGraph2D backgroundColor="#000011" {...commonGraphProps} />
        ) : graphType === "vr" ? (
          <ForceGraphVR {...commonGraphProps} />
        ) : graphType === "ar" ? (
          <ForceGraphAR {...commonGraphProps} />
        ) : (
          <></>
        )}
      </Box>
      <Menu
        keepMounted
        open={menuState.mouseY != null}
        onClose={handleMenuClick(() => {})}
        anchorReference="anchorPosition"
        anchorPosition={
          menuState.mouseY != null && menuState.mouseX != null
            ? { top: menuState.mouseY, left: menuState.mouseX }
            : undefined
        }
      >
        {contextMenu()}
      </Menu>
    </>
  );
}

export default GraphComponent;
